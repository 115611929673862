/* css reset !! */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  font-size: 16px;
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Daxline Offc";
  src:  url('assets/fonts/DaxlineOffc.woff2') format('woff2'), /* Super Modern Browsers */
        url('assets/fonts/DaxlineOffc.woff') format('woff'), /* Pretty Modern Browsers */
        url('assets/fonts/DaxlineOffc.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Daxline Offc";
  src:  url('assets/fonts/DaxlineOffc-Bold.woff2') format('woff2'), /* Super Modern Browsers */
        url('assets/fonts/DaxlineOffc-Bold.woff') format('woff'), /* Pretty Modern Browsers */
        url('assets/fonts/DaxlineOffc-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: bold;
}

@font-face {
  font-family: "Daxline SC Offc";
  src:  url('assets/fonts/DaxlineScOffc.woff2') format('woff2'), /* Super Modern Browsers */
        url('assets/fonts/DaxlineScOffc.woff') format('woff'), /* Pretty Modern Browsers */
        url('assets/fonts/DaxlineScOffc.ttf')  format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Daxline SC Offc Bold";
  src:  url('assets/fonts/DaxlineScOffc-Bold.woff2') format('woff2'), /* Super Modern Browsers */
        url('assets/fonts/DaxlineScOffc-Bold.woff') format('woff'), /* Pretty Modern Browsers */
        url('assets/fonts/DaxlineScOffc-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
}

a {
  text-decoration: none;
  color: #e77300;
}

.clickable {
  cursor: pointer;
  color: #e77300;
}

.clickable:hover {
  color: #181919;
}

.text-orange {
  color: #e77300;
}

body {
  background-color: #f6f6f6;
}

.container {
  margin: 0 auto;
  font-family: "Daxline Offc", sans-serif;
  background: pink;
}

.container .flex {
  max-width: 1600px;
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-around;
}
